import React, { Suspense, useEffect, useRef, useState } from 'react';
var bc = new BroadcastChannel('auth_channel');
var authWord = 'login';
export var OIDCAuthIFrame = function (props) {
    var authControllerInstance = props.authControllerInstance, children = props.children, preloader = props.preloader;
    var _a = useState(false), allowRenderContent = _a[0], setAllowRenderContent = _a[1];
    var _b = useState(false), iFrameVisible = _b[0], setIFrameVisible = _b[1];
    var formContainerRef = useRef(null);
    var iFrameVisibleHandler = function (visible) {
        if (!visible) {
            bc.postMessage('login');
        }
        setIFrameVisible(visible);
    };
    var allowRenderContentHandler = function (status) {
        status && setAllowRenderContent(true);
    };
    var errorHandler = function (errorNoTyped) {
        var error = errorNoTyped;
        if ((error === null || error === void 0 ? void 0 : error.type) === 'redirect') {
            window.location.replace(error.url);
        }
    };
    useEffect(function () {
        var _a;
        if (formContainerRef.current === null)
            return;
        // Монтирование экземпляра iframe в dom
        if ('iframeElement' in authControllerInstance)
            (_a = formContainerRef.current) === null || _a === void 0 ? void 0 : _a.appendChild(authControllerInstance.iframeElement);
        // Слушатель события для скрытия iframe
        authControllerInstance.addEventListener('formVisible', iFrameVisibleHandler);
        // Слушатель события предотвращающий рендер приложения если изначально пользователь не авторизован
        authControllerInstance.addEventListener('authStatus', allowRenderContentHandler);
        authControllerInstance.addEventListener('error', errorHandler);
        // Инициация запроса статуса авторизации
        authControllerInstance.selfAuthCheck();
        return function () {
            authControllerInstance.removeEventListener('formVisible', iFrameVisibleHandler);
            authControllerInstance.removeEventListener('authStatus', allowRenderContentHandler);
        };
    }, []);
    useEffect(function () {
        if (iFrameVisible)
            return;
        bc.onmessage = function (_a) {
            var data = _a.data;
            if (data !== authWord)
                return;
            setTimeout(function () {
                window.location.reload();
            }, 300);
        };
    }, [iFrameVisible]);
    useEffect(function () {
        return function () {
            bc.close();
        };
    }, []);
    return (React.createElement(Suspense, { fallback: 'loading...' },
        React.createElement("div", { ref: formContainerRef, style: {
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: 'auto',
                width: '100vw',
                height: '100vh',
                zIndex: 99999,
                background: '#00000070',
                backdropFilter: 'blur(2px)',
                display: iFrameVisible ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
            } }),
        allowRenderContent ? children : preloader));
};
