import { __assign, __awaiter, __generator } from "tslib";
var OIDCAuthController = /** @class */ (function () {
    function OIDCAuthController(_a) {
        var Axios = _a.Axios, _b = _a.logoutApiUrl, logoutApiUrl = _b === void 0 ? 'authentication/logout' : _b, _c = _a.loginApiUrl, loginApiUrl = _c === void 0 ? 'authentication/login' : _c, _d = _a.checkApiUrl, checkApiUrl = _d === void 0 ? 'authentication/check' : _d;
        this.eventListeners = {};
        this.frozenRequestsStack = [];
        this.isAuth = false;
        this.isError = false;
        this.isOpenForm = false;
        this.axios = Axios;
        this.loginApiUrl = loginApiUrl;
        this.checkApiUrl = checkApiUrl;
        this.logoutApiUrl = logoutApiUrl;
        Axios.defaults.withCredentials = true;
        this.iframeElement = document.createElement('iframe');
        this.iframeElement.style.width = '100vw';
        this.iframeElement.style.height = '100vh';
        this.iframeElement.style.border = 'none';
        this.axios.interceptors.request.use(this.requestFulfilledInterceptor.bind(this));
        this.axios.interceptors.response.use(undefined, this.responseRejectedInterceptor.bind(this));
    }
    // Метод для добавления слушателя события
    OIDCAuthController.prototype.addEventListener = function (eventName, callback) {
        if (!this.eventListeners[eventName]) {
            this.eventListeners[eventName] = [];
        }
        this.eventListeners[eventName].push(callback);
    };
    // Метод для удаления слушателя события
    OIDCAuthController.prototype.removeEventListener = function (eventName, callback) {
        if (this.eventListeners[eventName]) {
            this.eventListeners[eventName] = this.eventListeners[eventName].filter(function (cb) { return cb !== callback; });
        }
    };
    // Метод для генерации события
    OIDCAuthController.prototype.dispatchEvent = function (eventName, data) {
        var _this = this;
        if (this.eventListeners[eventName]) {
            this.eventListeners[eventName].forEach(function (callback) { return callback.call(_this, data); });
        }
    };
    // Слушатель postMessage окна ввода кредов
    OIDCAuthController.prototype.postMessageListener = function (event) {
        if (typeof event.data !== 'string')
            return;
        var answer = JSON.parse(event.data);
        if (answer.type === 'action' && answer.value === 'close') {
            this.isError = true;
            this.dispatchEvent('error', true);
        }
        else if (answer.type === 'status' && answer.value === 'success') {
            this.closeForm();
            this.isAuth = true;
            this.dispatchEvent('authStatus', true);
            this.unFreezeResponse();
        }
        else if (answer.type === 'redirect')
            location.replace(answer.value);
    };
    // Инициатор ввода кредов
    OIDCAuthController.prototype.initAuth = function (response) {
        this.isAuth = false;
        this.dispatchEvent('authStatus', false);
        this.authorize(response.config.baseURL).then();
        return this.freezeResponse(response);
    };
    // Метод инициации авторизации, открыть окно ввода кредов, или выполнить повторный вызов замороженных запросов
    OIDCAuthController.prototype.authorize = function (baseURL) {
        if (baseURL === void 0) { baseURL = this.axios.defaults.baseURL; }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios
                            .get(this.loginApiUrl, { baseURL: baseURL })
                            .then(function (_a) {
                            var _b, _c;
                            var data = _a.data;
                            if (typeof data === 'boolean') {
                                // Обработка когда приходит беловой значение
                                // если приходит то только true, так как в случае false мы не авторизованы и придет моделька с redirect
                                _this.isAuth = true;
                                _this.dispatchEvent('authStatus', true);
                                _this.unFreezeResponse();
                            }
                            else {
                                if (((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.isLoggedIn) === false)
                                    _this.openForm(data === null || data === void 0 ? void 0 : data.data.redirectUri);
                                else if (((_c = data === null || data === void 0 ? void 0 : data.data) === null || _c === void 0 ? void 0 : _c.isLoggedIn) === true) {
                                    _this.isAuth = true;
                                    _this.dispatchEvent('authStatus', true);
                                    _this.unFreezeResponse();
                                }
                                else
                                    console.error('Incorrect answer - data.data.isLoggedIn OR data?.content?.isLoggedIn not found. Raw response: ', data);
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // Открыть форму ввода кредов в айфрейме
    OIDCAuthController.prototype.openForm = function (url) {
        this.isOpenForm = true;
        this.dispatchEvent('formVisible', true);
        this.iframeElement.src = url;
        window.addEventListener('message', this.postMessageListener.bind(this));
    };
    // Закрыть форму ввода кредов в айфрейме
    OIDCAuthController.prototype.closeForm = function () {
        this.isOpenForm = false;
        this.dispatchEvent('formVisible', false);
        // this.iframeElement.src = null
        window.removeEventListener('message', this.postMessageListener);
    };
    // Запомнить конфиг неудачного запроса в стэк замороженных реквестов
    OIDCAuthController.prototype.freezeResponse = function (response) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.frozenRequestsStack.push(function () { return resolve(_this.axios(__assign({}, response.config))); });
        });
    };
    // Перезапросить все замороженные реквесты из стэка
    OIDCAuthController.prototype.unFreezeResponse = function () {
        this.frozenRequestsStack.forEach(function (response) { return response(); });
        this.frozenRequestsStack = [];
    };
    // интерцептор неудачного респонса
    OIDCAuthController.prototype.responseRejectedInterceptor = function (error) {
        var _a, _b, _c, _d, _e, _f, _g;
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
            if (this.isAuth)
                return this.initAuth(error.response);
            else
                return this.freezeResponse(error.response);
        }
        if (((_b = error.response) === null || _b === void 0 ? void 0 : _b.status) === 403 && ((_d = (_c = error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.description) === "Необходимо провести обязательные действия для входа в систему") {
            this.dispatchEvent('error', {
                type: 'redirect',
                url: (_g = (_f = (_e = error.response) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.redirectUri
            });
        }
        return Promise.reject(error);
    };
    // Внешний метод для вызова принудительной авторизации сервиса по baseURL
    OIDCAuthController.prototype.authorizeService = function (service, isCheckAuth) {
        if (isCheckAuth === void 0) { isCheckAuth = true; }
        return __awaiter(this, void 0, void 0, function () {
            var responseAuthStatus;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!isCheckAuth) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.axios.get(this.checkApiUrl, {
                                baseURL: service,
                            })];
                    case 1:
                        responseAuthStatus = (_a.sent()).data.data;
                        if (responseAuthStatus)
                            return [2 /*return*/, true];
                        this.isAuth = false;
                        this.dispatchEvent('authStatus', false);
                        _a.label = 2;
                    case 2: return [4 /*yield*/, this.authorize(service)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, this.isAuth];
                }
            });
        });
    };
    // логаут определенного сервиса (на момент релизации логаут общий)
    OIDCAuthController.prototype.logoutService = function (service) {
        if (service === void 0) { service = this.axios.defaults.baseURL; }
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isAuth = false;
                        return [4 /*yield*/, this.axios.get(this.logoutApiUrl, { baseURL: service })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    // интерцептор успешного реквеста
    OIDCAuthController.prototype.requestFulfilledInterceptor = function (config) {
        var _this = this;
        if (this.isAuth === false &&
            config.url !== this.loginApiUrl &&
            config.url !== this.logoutApiUrl &&
            config.url !== this.checkApiUrl) {
            return new Promise(function (resolve) {
                _this.frozenRequestsStack.push(function () { return resolve(config); });
            });
        }
        return config;
    };
    // Метод проверки авторизации собственного api приложения
    OIDCAuthController.prototype.selfAuthCheck = function () {
        return __awaiter(this, void 0, void 0, function () {
            var responseAuthStatus;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.axios.get(this.checkApiUrl)];
                    case 1:
                        responseAuthStatus = (_a.sent()).data.data;
                        if (!responseAuthStatus) return [3 /*break*/, 2];
                        this.isAuth = true;
                        this.dispatchEvent('authStatus', true);
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.authorize()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return OIDCAuthController;
}());
export { OIDCAuthController };
